import { helixActivePanelsKey } from '../../constants';
import mutations from './mutations';

const initializeHelixActivePanels = () => {
  const rawActivePanels = localStorage.getItem(helixActivePanelsKey);
  try {
    const activePanels = JSON.parse(rawActivePanels);
    if (!Array.isArray(activePanels)) {
      throw new Error('Invalid active panels');
    }
    return activePanels;
  } catch (error) {
    console.error('Failed to parse active panels from local storage', error);
    return [];
  }
};

const state = {
  helixActivePanels: initializeHelixActivePanels()
};

export default {
  namespaced: true,
  state,
  mutations
};
