import { appThemeKey } from '../../constants';
import mutations from './mutations';

const state = {
  appTheme: localStorage.getItem(appThemeKey) || 'light' // Initialize theme from local storage
};

export default {
  namespaced: true,
  state,
  mutations
};
