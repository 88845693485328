<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <UsageExample v-model="tab" :code="code" :tabs="tabs">
          <div class="sectionTitle">Static Chips</div>
          <!-- statis chips -->
          <v-row v-for="(color, index) in chipColors" :key="color">
            <v-col>
              <hx-chip
                v-model:active="isSelectedInverted"
                :color="color"
                :label="index > 3 ? `${getRandomNumber()}` : label"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelectedInverted"
                :color="color"
                show-left-icon
                :left-icon="demoIcons[index % demoIcons.length]"
                :label="index > 3 ? `` : label"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelected"
                :color="color"
                :label="index > 3 ? `${getRandomNumber()}` : label"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelected"
                :color="color"
                show-right-icon
                :left-icon="demoIcons[index % demoIcons.length]"
                :right-icon="demoIcons[demoIcons.length - 1 - (index % demoIcons.length)]"
                :label="index > 3 ? `` : label"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelected"
                disabled
                :color="color"
                :label="index > 3 ? `${getRandomNumber()}` : label"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelected"
                disabled
                :color="color"
                show-left-icon
                show-right-icon
                :left-icon="demoIcons[index % demoIcons.length]"
                right-icon="mdi-check"
                :label="index > 3 ? `` : label"
              ></hx-chip>
            </v-col>
          </v-row>
          <div class="sectionTitle">Action Chips (toggle active state with v-model)</div>
          <div class="sectionDescription">
            <v-icon class="pulse">mdi-cursor-default-click</v-icon>
            Click/Hover/Hold on the chip to view different color states
          </div>

          <!-- action chips - toggle v-model active state -->
          <v-row v-for="color in chipColors" :key="color">
            <v-col>
              {{ color.toUpperCase() }}
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelectedInverted"
                :color="color"
                :show-left-icon="isSelectedInverted"
                type="action"
                :label="`${isSelectedInverted ? 'Active' : 'Inactive'}`"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelected"
                :color="color"
                :show-left-icon="isSelected"
                type="action"
                :label="`${isSelected ? 'Active' : 'Inactive'}`"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-model:active="isSelected"
                :disabled="isSelected"
                :color="color"
                show-left-icon
                show-right-icon
                :left-icon="isSelected ? 'mdi-arrow-up' : 'mdi-arrow-down'"
                :right-icon="isSelected ? 'mdi-arrow-left' : 'mdi-arrow-right'"
                type="action"
                :label="label"
              ></hx-chip>
            </v-col>
          </v-row>

          <div class="sectionTitle">Action Chips with onClick events (see console log)</div>
          <div class="sectionDescription">
            <v-icon class="pulse">mdi-cursor-default-click</v-icon>
            Click on the chip to increase the number of clicks shown in the disabled variant
          </div>
          <!-- action chips - Button with onClick events -->
          <v-row v-for="(color, index) in chipColors" :key="color">
            <v-col>
              {{ color.toUpperCase() }}
            </v-col>
            <v-col>
              <hx-chip
                :active="false"
                :color="color"
                show-left-icon
                left-icon="mdi-arrow-up"
                type="action"
                :label="label"
                @click="onClick"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                :active="true"
                :color="color"
                show-right-icon
                type="action"
                right-icon="mdi-arrow-right"
                :label="label"
                @click="onClick"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                disabled
                :color="color"
                show-left-icon
                show-right-icon
                type="action"
                :left-icon="demoIcons[index % demoIcons.length]"
                right-icon="mdi-arrow-right"
                :label="`Number of Clicks: ${numberOfClicks}`"
                @click="onClick"
              ></hx-chip>
            </v-col>
          </v-row>

          <div class="sectionTitle">Input Chips</div>
          <div class="sectionDescription">
            <v-icon class="pulse">mdi-cursor-default-click</v-icon>
            Click on the ✖︎ to dismiss the chip
          </div>
          <!-- input chips -->
          <v-row v-for="color in ['grey', 'teal']" :key="color">
            <v-col>
              <hx-chip
                v-if="!hiddenChips.includes(`${color}1`)"
                :active="false"
                :color="color"
                show-right-icon
                type="input"
                :label="label"
                @dismiss="onDismiss(color, 1)"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-if="!hiddenChips.includes(`${color}2`)"
                :active="true"
                :color="color"
                show-right-icon
                type="input"
                :label="label"
                @dismiss="onDismiss(color, 2)"
              ></hx-chip>
            </v-col>
            <v-col>
              <hx-chip
                v-if="!hiddenChips.includes(`${color}3`)"
                disabled
                :color="color"
                show-right-icon
                type="input"
                :label="label"
                @dismiss="onDismiss(color, 3)"
              ></hx-chip>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <hx-chip
                color="blue"
                show-left-icon
                left-icon="mdi-restore"
                type="action"
                label="Restore Input Chips"
                @click="hiddenChips = []"
              >
              </hx-chip>
            </v-col>
          </v-row>
        </UsageExample>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsageExample from '@/components/helix/utils/UsageExample';

export default {
  name: 'HelixChips',
  components: { UsageExample },
  setup() {
    const chipColors = ['grey', 'blue', 'pink', 'red', 'yellow', 'green', 'teal'];
    const demoIcons = ['mdi-circle', 'mdi-triangle', 'mdi-square', 'mdi-hexagon'];
    const code = `<hx-chip id="demo-chip-1" label="Label" />
<hx-chip label="Disabled" disabled />
<hx-chip label="99" color="yellow" />
<hx-chip show-left-icon left-icon="mdi-arrow-up" />
<hx-chip show-right-icon right-icon="mdi-arrow-right" active />

<hx-chip
  v-model:active="isSelected"
  label="Toggle Chip"
  color="blue"
  type="action"
  :show-left-icon="isSelected"
/>
<hx-chip
  color="pink"
  active
  show-left-icon
  show-right-icon
  type="action"
  left-icon="mdi-triangle"
  right-icon="mdi-arrow-right"
  label="Clickable Chip"
  @click="onClick"
></hx-chip>

<hx-chip
  v-if="!hiddenChips.includes('chip1')"
  label="Input Chip"
  color="green"
  type="input"
  show-right-icon
  @dismiss="onDismiss('chip', 1)"
/>
`;
    return {
      chipColors,
      demoIcons,
      code
    };
  },
  data() {
    return {
      label: 'Chip Label',
      tab: 'Chip Usage',
      tabs: ['Chip Usage'],
      indeterminate: true,
      isSelected: true,
      hiddenChips: [],
      numberOfClicks: 0
    };
  },
  computed: {
    isSelectedInverted: {
      get() {
        return !this.isSelected;
      },
      set(value) {
        this.isSelected = !value;
      }
    }
  },
  methods: {
    getRandomNumber() {
      return Math.floor(Math.random() * 100);
    },
    onClick() {
      console.log('Clicked');
      this.numberOfClicks += 1;
    },
    onDismiss(color, index) {
      console.log('Dismissed');
      this.hiddenChips.push(`${color}${index}`);
    }
  }
};
</script>

<style scoped>
.sectionTitle {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.sectionDescription {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}
@keyframes pulse {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.pulse {
  animation: pulse 2s infinite;
}
</style>
