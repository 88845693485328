<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <UsageExample v-model="tab" :code="code" :name="name" :tabs="tabs">
          <v-row>
            <v-col>
              <hx-checkbox v-model="isSelected"></hx-checkbox>
            </v-col>
            <v-col>
              <hx-checkbox v-model="isSelected" disabled></hx-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <hx-checkbox v-model="isSelected" label="Checkbox with label"></hx-checkbox>
            </v-col>
            <v-col>
              <hx-checkbox v-model="isSelected" label="Checkbox with label" disabled></hx-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <hx-checkbox
                v-model="isSelected"
                v-model:indeterminate="indeterminate"
                label="Checkbox with indeterminate state"
              ></hx-checkbox>
            </v-col>
            <v-col>
              <hx-checkbox
                v-model="isSelected"
                v-model:indeterminate="indeterminate"
                label="Checkbox with indeterminate state"
                disabled
              ></hx-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <hx-checkbox v-model="isSelected">
                <template #label>
                  <div class="d-flex align-center">
                    <span>I agree to Ideagen's <u class="text-primary">Privacy Policy</u></span>
                  </div>
                </template>
              </hx-checkbox>
            </v-col>
            <v-col>
              <hx-checkbox v-model="isSelected" disabled>
                <template #label>
                  <div class="d-flex align-center">
                    <span>I agree to Ideagen's <u class="text-primary">Privacy Policy</u></span>
                  </div>
                </template>
              </hx-checkbox>
            </v-col>
          </v-row>

          <template #configuration>
            <hx-checkbox v-model="isSelected" :label="`Select state: ${isSelected}`"></hx-checkbox>
            <hx-checkbox
              v-model="indeterminate"
              :label="`Indeterminate state: ${indeterminate}`"
            ></hx-checkbox>
          </template>
        </UsageExample>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsageExample from '@/components/helix/utils/UsageExample';

export default {
  name: 'HelixCheckboxes',
  components: { UsageExample },
  data() {
    return {
      name: 'hx-checkbox',
      tab: 'Checkbox Usage',
      tabs: ['Checkbox Usage'],
      indeterminate: true,
      isSelected: true
    };
  },
  computed: {
    code() {
      return `<hx-checkbox v-model="isSelected"></hx-checkbox>
<hx-checkbox v-model="isSelected" disabled></hx-checkbox>

<hx-checkbox v-model="isSelected" label="Checkbox with label"></hx-checkbox>
<hx-checkbox v-model="isSelected" label="Checkbox with label" disabled></hx-checkbox>

<hx-checkbox
  v-model:indeterminate="indeterminate"
  label="Checkbox with indeterminate state"
></hx-checkbox>
<hx-checkbox
  v-model:indeterminate="indeterminate"
  label="Checkbox with indeterminate state"
  disabled
></hx-checkbox>

<hx-checkbox v-model="isSelected">
  <template #label>
    <div class="d-flex align-center">
      <span>I agree to Ideagen's <u class="text-primary">Privacy Policy</u></span>
    </div>
  </template>
</hx-checkbox>
<hx-checkbox v-model="isSelected" disabled>
  <template #label>
    <div class="d-flex align-center">
      <span>I agree to Ideagen's <u class="text-primary">Privacy Policy</u></span>
    </div>
  </template>
</hx-checkbox>
`;
    }
  }
};
</script>
