<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Default</v-card-title>
          <v-card-subtitle>
            Defaults: [variant: secondary | size: medium | ghost: false]
          </v-card-subtitle>
          <v-card-text class="d-flex ga-3 flex-wrap">
            <hx-button id="btn-default" label="Button" />
            <hx-button id="btn-default-disabled" label="Disabled" disabled />
            <hx-button id="btn-default-disabled" label="Disabled" loading />
            <hx-button id="btn-default-disabled" label="Disabled" disabled loading />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Demo</v-card-title>
          <v-card-subtitle>Sample button with onClick handler</v-card-subtitle>
          <v-card-text class="d-flex flex-wrap align-center">
            <hx-button
              id="btn-click-me"
              variant="primary"
              label="Click Me"
              @click="incrementCounter"
            ></hx-button>
            <div class="mx-4 my-1 text-primary">Clicks: {{ counter }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Contained Button</v-card-title>
          <v-card-subtitle>
            size: small, medium, large | variant: primary, secondary, danger
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row
                v-for="(size, sizeIndex) in ['small', 'medium', 'large']"
                :key="sizeIndex"
                align="center"
              >
                <v-col
                  v-for="(variant, variantIndex) in ['primary', 'secondary', 'danger']"
                  :key="variantIndex"
                >
                  <div class="d-flex ga-8">
                    <hx-button :variant :size label="Button" :disabled :loading />
                    <hx-button
                      :variant
                      :size
                      label="Button"
                      :disabled
                      :loading
                      left-icon="mdi-plus-circle-outline"
                      right-icon="mdi-chevron-down"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Ghost Button</v-card-title>
          <v-card-subtitle>
            size: small, medium, large | variant: primary, secondary, danger, white
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row
                v-for="(size, sizeIndex) in ['small', 'medium', 'large']"
                :key="sizeIndex"
                align="center"
              >
                <v-col
                  v-for="(variant, variantIndex) in ['primary', 'secondary', 'danger', 'white']"
                  :key="variantIndex"
                  :class="variant === 'white' ? 'bg-primary' : ''"
                >
                  <div class="d-flex ga-8">
                    <hx-button ghost :variant :size label="Button" :disabled :loading />
                    <hx-button
                      ghost
                      :variant
                      :size
                      label="Button"
                      :disabled
                      :loading
                      left-icon="mdi-plus-circle-outline"
                      right-icon="mdi-chevron-down"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Controls</v-card-title>
          <v-card-text class="d-flex justify-space-around">
            <v-switch
              v-model="loading"
              :label="`Loading: ${loading.toString()}`"
              color="primary"
              hide-details
            ></v-switch>

            <v-switch
              v-model="disabled"
              :label="`Disabled: ${disabled.toString()}`"
              color="primary"
              hide-details
            ></v-switch>

            <v-switch
              v-model="showValidationError"
              :label="`Show validation error: ${showValidationError.toString()}`"
              color="primary"
              hide-details
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="showValidationError" v-show="!showValidationError">
      <hx-button
        id="btn-validation-error"
        variant="destructive"
        size="xl"
        label=""
        type="contained"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelixButtons',
  data() {
    return {
      loading: false,
      disabled: false,
      counter: 0,
      showValidationError: false
    };
  },
  methods: {
    incrementCounter() {
      this.counter += 1;
    }
  }
};
</script>
