import { defaultViewType } from '../../constants';
import mutations from './mutations';

function initializeViewType() {
  // remap current window pathname to viewType
  const pathname = window.location.pathname.split('/')[1];
  if (pathname === '/') {
    return defaultViewType;
  }

  return pathname;
}

const state = {
  viewType: initializeViewType()
};

export default {
  namespaced: true,
  state,
  mutations
};
