<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Default</v-card-title>
          <v-card-text class="d-flex ga-3 flex-wrap justify-space-between">
            <hx-tooltip id="tooltip-left" v-slot="props" text="Tooltip text at left">
              <hx-button variant="primary" label="Left" v-bind="props"></hx-button>
            </hx-tooltip>
            <hx-tooltip
              id="tooltip-right"
              v-slot="props"
              text="Tooltip text at right"
              location="right"
            >
              <hx-button variant="primary" label="Right" v-bind="props"></hx-button>
            </hx-tooltip>
            <hx-tooltip id="tooltip-top" v-slot="props" text="Tooltip text at top" location="top">
              <hx-button variant="primary" label="Top" v-bind="props"></hx-button>
            </hx-tooltip>
            <hx-tooltip
              id="tooltip-bottom"
              v-slot="props"
              text="Tooltip text at bottom"
              location="bottom"
            >
              <hx-button variant="primary" label="Bottom" v-bind="props"></hx-button>
            </hx-tooltip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelixTooltip'
};
</script>
