<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <UsageExample v-model="tab" :code="code" :tabs="tabs">
          <div class="wrapper">
            <v-container>
              <v-row>
                <div class="grid-container">
                  <div class="sectionTitle">Examples</div>
                  <div class="sectionTitle">Compact (Default)</div>
                  <div class="sectionTitle">Regular</div>

                  <div>Single Select</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Multi Select</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      multiple
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      multiple
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>With Icon (leftIcon=mdi-content-copy)</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      left-icon="mdi-clipboard-check-outline"
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      left-icon="mdi-content-copy"
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Multi Select with Icon (leftIcon=mdi-content-copy)</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      left-icon="mdi-clipboard-check-outline"
                      size="compact"
                      multiple
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      left-icon="mdi-content-copy"
                      size="regular"
                      multiple
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>With Chevron (showChevron)</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      :label="label"
                      :caption="caption"
                      show-chevron
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      :label="label"
                      :caption="caption"
                      show-chevron
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Multi Select with Chevron</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      :label="label"
                      :caption="caption"
                      multiple
                      show-chevron
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      :label="label"
                      :caption="caption"
                      multiple
                      show-chevron
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Show Chip (showChip)</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      show-chip
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      show-chip
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Multi Select with Chip</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      multiple
                      show-chip
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      multiple
                      show-chip
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>Show Chip (customize with chipProps)</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      show-chip
                      :chip-props="chipProps"
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      :label="label"
                      :caption="caption"
                      show-chip
                      :chip-props="chipProps"
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Long Label</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      show-chevron
                      :label="longLabel"
                      :caption="caption"
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      show-chevron
                      :label="longLabel"
                      :caption="caption"
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Long Caption</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      show-chevron
                      :label="label"
                      :caption="longCaption"
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      show-chevron
                      :label="label"
                      :caption="longCaption"
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>Long Label & Caption</div>
                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      show-chevron
                      :label="longLabel"
                      :caption="longCaption"
                      size="compact"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>

                  <div>
                    <hx-option
                      v-model:isSelected="isSelected"
                      v-model:isExpanded="isExpanded"
                      show-chevron
                      :label="longLabel"
                      :caption="longCaption"
                      size="regular"
                      :disabled="isDisabled"
                    ></hx-option>
                  </div>
                </div>
              </v-row>
            </v-container>
          </div>

          <template #configuration>
            <div class="d-flex flex-column mt-16">
              <hx-checkbox
                v-model="isSelected"
                :label="`Select state: ${isSelected}`"
              ></hx-checkbox>
              <hx-checkbox
                v-model="isExpanded"
                :label="`Expand state: ${isExpanded}`"
              ></hx-checkbox>
              <hx-checkbox v-model="isDisabled" :label="`Disabled: ${isDisabled}`"></hx-checkbox>
            </div>
          </template>
        </UsageExample>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsageExample from '@/components/helix/utils/UsageExample';

export default {
  name: 'HelixOptions',
  components: { UsageExample },
  setup() {
    const label = 'Label';
    const caption = 'Caption';
    const longLabel = `Audit #103: Each of the variants can be customised to show different combinations of the content in the image above. `;
    const longCaption = `Nulla dolor velit adipisicing duis excepteur esse in duis nostrud occaecat mollit incididunt deserunt sunt. Ut ut
    sunt laborum ex occaecat eu tempor labore enim adipisicing minim ad. Est in quis eu dolore occaecat excepteur fugiat
    dolore nisi aliqua fugiat enim ut cillum. Labore enim duis nostrud eu. Est ut eiusmod consequat irure quis deserunt
    ex. Enim laboris dolor magna pariatur. Dolor et ad sint voluptate sunt elit mollit officia ad enim sit consectetur
    enim.`;
    const code = `
<div>Single Select</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Multi Select</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      multiple
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      multiple
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>With Icon (leftIcon=mdi-content-copy)</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      left-icon="mdi-clipboard-check-outline"
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      left-icon="mdi-content-copy"
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Multi Select with Icon (leftIcon=mdi-content-copy)</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      left-icon="mdi-clipboard-check-outline"
      size="compact"
      multiple
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      left-icon="mdi-content-copy"
      size="regular"
      multiple
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>With Chevron (showChevron)</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      :label="label"
      :caption="caption"
      show-chevron
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      :label="label"
      :caption="caption"
      show-chevron
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Multi Select with Chevron</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      :label="label"
      :caption="caption"
      multiple
      show-chevron
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      :label="label"
      :caption="caption"
      multiple
      show-chevron
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Show Chip (showChip)</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      show-chip
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      show-chip
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Multi Select with Chip</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      multiple
      show-chip
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      multiple
      show-chip
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>Show Chip (customize with chipProps)</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      show-chip
      :chip-props="chipProps"
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      :label="label"
      :caption="caption"
      show-chip
      :chip-props="chipProps"
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Long Label</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      show-chevron
      :label="longLabel"
      :caption="caption"
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      show-chevron
      :label="longLabel"
      :caption="caption"
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Long Caption</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      show-chevron
      :label="label"
      :caption="longCaption"
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      show-chevron
      :label="label"
      :caption="longCaption"
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>Long Label & Caption</div>
  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      show-chevron
      :label="longLabel"
      :caption="longCaption"
      size="compact"
      :disabled="isDisabled"
    ></hx-option>
  </div>

  <div>
    <hx-option
      v-model:isSelected="isSelected"
      v-model:isExpanded="isExpanded"
      show-chevron
      :label="longLabel"
      :caption="longCaption"
      size="regular"
      :disabled="isDisabled"
    ></hx-option>
  </div>
    `;
    return {
      label,
      caption,
      longLabel,
      longCaption,
      code
    };
  },
  data() {
    return {
      tab: 'Option Usage',
      tabs: ['Option Usage'],
      isSelected: true,
      isExpanded: false,
      isDisabled: false,
      chipProps: {
        color: 'red',
        active: true,
        label: 'Missing'
      }
    };
  }
};
</script>

<style scoped>
.wrapper {
  background-color: rgb(238, 238, 238);
}
.sectionTitle {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.grid-container {
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 30px;
  padding: 10px;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
</style>
