<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row class="d-flex justify-space-between">
      <v-btn color="primary" @click="showHxModalButton3 = true">Helix Modal with 3 buttons</v-btn>
      <HxModal
        v-model="showHxModalButton3"
        title="Modal Title"
        subtitle="Modal Subtitle"
        :primary-action="{
          label: 'Button',
          leftIcon: 'mdi-plus-circle-outline',
          rightIcon: 'mdi-chevron-down',
          variant: 'primary'
        }"
        :secondary-action1="{
          label: 'Button',
          leftIcon: 'mdi-plus-circle-outline',
          rightIcon: 'mdi-chevron-down',
          variant: 'secondary'
        }"
        :secondary-action2="{
          label: 'Button',
          leftIcon: 'mdi-plus-circle-outline',
          rightIcon: 'mdi-chevron-down',
          variant: 'secondary'
        }"
        @primary-click="showHxModalButton3 = false"
        @secondary1-click="showHxModalButton3 = false"
        @secondary2-click="showHxModalButton3 = false"
        ><template #content>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p></template
        >
      </HxModal>
      <v-btn color="primary" @click="showHxModalButton2 = true">Helix Modal with 2 buttons</v-btn>

      <HxModal
        v-model="showHxModalButton2"
        title="Modal Title"
        subtitle="Modal Subtitle"
        :primary-action="{
          label: 'Button',
          leftIcon: 'mdi-plus-circle-outline',
          rightIcon: 'mdi-chevron-down',
          variant: 'primary'
        }"
        :secondary-action1="{
          label: 'Button',
          leftIcon: 'mdi-plus-circle-outline',
          rightIcon: 'mdi-chevron-down',
          variant: 'secondary'
        }"
        @primary-click="showHxModalButton2 = false"
        @secondary1-click="showHxModalButton2 = false"
        ><template #content>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p></template
        ></HxModal
      >
      <v-btn color="primary" @click="showHxModalButton1 = true">Helix Modal with 1 button</v-btn>

      <HxModal
        v-model="showHxModalButton1"
        title="Modal Title"
        subtitle="Modal Subtitle"
        :primary-action="{
          label: 'Button',
          leftIcon: 'mdi-plus-circle-outline',
          rightIcon: 'mdi-chevron-down',
          variant: 'primary'
        }"
        @primary-click="showHxModalButton1 = false"
        ><template #content>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p></template
        ></HxModal
      >
    </v-row>
    <v-row class="d-flex justify-center">
      <v-btn color="primary" @click="showHxModalWithError = true"
        >Helix Modal without title, content, button</v-btn
      >
      <HxModal v-model="showHxModalWithError" subtitle="Modal Subtitle"></HxModal>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelixModal',
  data() {
    return {
      showHxModalButton3: false,
      showHxModalButton2: false,
      showHxModalButton1: false,
      showHxModalWithError: false
    };
  }
};
</script>
