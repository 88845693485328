<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Default</v-card-title>
          <v-card-text class="d-flex ga-3 flex-wrap">
            <HxToggleSwitch
              v-model="modelValue"
              title-text="Title"
              left-label="Off"
              right-label="On"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Disabled Inactive</v-card-title>
          <v-card-text class="d-flex ga-3 flex-wrap">
            <HxToggleSwitch disabled left-label="Off" right-label="On" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Disabled Active</v-card-title>
          <v-card-text class="d-flex ga-3 flex-wrap">
            <HxToggleSwitch v-model="disabledModel" disabled left-label="Off" right-label="On" />
          </v-card-text> </v-card
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelixToggleSwitches',
  data() {
    return {
      modelValue: false,
      disabledModel: true
    };
  }
};
</script>
