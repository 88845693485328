<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <UsageExample v-model="tab" :code="code" :tabs="tabs">
          <div class="wrapper">
            <v-container>
              <v-row no-gutters>
                <v-col> <v-sheet class="pa-2 ma-2"> Trigger </v-sheet> </v-col>
                <v-col>
                  <v-sheet class="pa-2 ma-2"> Compact </v-sheet>
                </v-col>
                <v-col>
                  <v-sheet class="pa-2 ma-2"> Regular </v-sheet>
                </v-col>

                <v-responsive width="100%"></v-responsive>

                <v-col> <v-sheet class="pa-2 ma-2"> Avatar </v-sheet> </v-col>

                <v-col>
                  <v-sheet class="pa-2 ma-2">
                    <HxMenu v-slot="props" :items="multipleMenuItems" compact @click="menuClick"
                      ><v-avatar v-bind="props" color="primary">A</v-avatar></HxMenu
                    >
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-sheet class="pa-2 ma-2">
                    <HxMenu v-slot="props" :items="multipleMenuItems" regular @click="menuClick"
                      ><v-avatar v-bind="props" color="primary">A</v-avatar></HxMenu
                    >
                  </v-sheet>
                </v-col>

                <v-responsive width="100%"></v-responsive>
                <v-col> <v-sheet class="pa-2 ma-2"> Button </v-sheet> </v-col>

                <v-col>
                  <v-sheet class="pa-2 ma-2">
                    <HxMenu v-slot="props" :items="multipleMenuItems" compact @click="menuClick"
                      ><v-btn v-bind="props" color="primary"> Menu </v-btn></HxMenu
                    >
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-sheet class="pa-2 ma-2">
                    <HxMenu v-slot="props" :items="multipleMenuItems" regular @click="menuClick"
                      ><v-btn v-bind="props" color="primary"> Menu </v-btn></HxMenu
                    >
                  </v-sheet>
                </v-col>

                <v-responsive width="100%"></v-responsive>

                <v-col> <v-sheet class="pa-2 ma-2"> Search </v-sheet> </v-col>

                <v-col>
                  <v-sheet class="pa-2 ma-2">
                    <HxMenu v-slot="props" :items="multipleMenuItems" compact @click="menuClick"
                      ><HxSearch v-bind="props" placeholder="Search" min-width="150px"></HxSearch
                    ></HxMenu>
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-sheet class="pa-2 ma-2">
                    <HxMenu v-slot="props" :items="multipleMenuItems" regular @click="menuClick"
                      ><HxSearch v-bind="props" placeholder="Search" min-width="150px"></HxSearch
                    ></HxMenu>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <template #configuration>
            <div class="d-flex flex-column mt-16">
              <v-select
                v-model="selectedValue"
                :items="['3', '10', '20', '30']"
                label="Select number of items to display"
                min-width="300px"
              ></v-select>
            </div>
          </template>
        </UsageExample>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsageExample from '@/components/helix/utils/UsageExample';

export default {
  name: 'HelixMenus',
  components: { UsageExample },
  setup() {
    const code = `
    <HxMenu v-slot="props" :items="items" compact
        ><v-avatar v-bind="props" color="primary">A</v-avatar></HxMenu
    >
    <HxMenu v-slot="props" :items="items" compact
        ><v-btn v-bind="props" color="primary"> Click to open menu </v-btn></HxMenu
    >
    <HxMenu v-slot="props" :items="items" compact>
        <HxSearch
        v-bind="props"
        placeholder="Search with Menu"
        clearable
        min-width="200px"
        ></HxSearch
    ></HxMenu>

     <HxMenu v-slot="props" :items="items" regular
        ><v-avatar v-bind="props" color="primary">A</v-avatar></HxMenu
    >
    <HxMenu v-slot="props" :items="items" regular
        ><v-btn v-bind="props" color="primary"> Click to open menu </v-btn></HxMenu
    >
    <HxMenu v-slot="props" :items="items" regular>
        <HxSearch
        v-bind="props"
        placeholder="Search with Menu"
        clearable
        min-width="200px"
        ></HxSearch
    ></HxMenu>

      `;
    return {
      code
    };
  },
  data() {
    return {
      tab: 'Menu Usage',
      tabs: ['Menu Usage'],
      menuItems: [
        {
          title: 'Go to home'
        }
      ],
      selectedValue: 3,
      items: [
        ...Array.from({ length: 30 }, (v, i) => ({
          title: `Click Me ${i + 1}`
        })),
        {
          title: 'Go to home'
        }
      ]
    };
  },
  computed: {
    multipleMenuItems() {
      return [...this.menuItems, ...this.items].slice(0, this.selectedValue);
    }
  },
  methods: {
    menuClick(item) {
      switch (item.title) {
        case 'Go to home':
          this.$router.push('/');
          break;
        default:
          console.log(`Action triggered: ${item.title}`);
      }
    }
  }
};
</script>

<style scoped>
.wrapper {
  background-color: rgb(238, 238, 238);
}
</style>
