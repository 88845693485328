import { helixActivePanelsKey } from '../../constants';
import * as types from '../../mutationTypes';

export default {
  [types.SET_HELIX_ACTIVE_PANELS](state, value) {
    const isValidValue = Array.isArray(value);
    if (!isValidValue) {
      state.helixActivePanels = [];
    } else {
      state.helixActivePanels = value;
      localStorage.setItem(helixActivePanelsKey, JSON.stringify(value)); // Save theme to local storage
    }
  }
};
