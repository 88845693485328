<template>
  <v-container class="d-flex flex-column ga-3">
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Default</v-card-title>
          <v-card-text class="d-flex ga-3 flex-wrap">
            <v-radio-group v-model="radioValue">
              Selected value: {{ radioValue }}
              <hx-radio-button id="btn-default" label="Radio One" value="one" />
              <hx-radio-button id="btn-disabled" label="Radio Two" value="two" />
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="4">
          <v-card-title class="font-weight-bold">Disabled</v-card-title>
          <v-card-text class="d-flex ga-3 flex-wrap">
            <v-radio-group v-model="radioValue">
              <hx-radio-button id="btn-disabled" label="Radio (Disabled)" value="one" disabled />
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelixRadioButtons',
  data() {
    return {
      radioValue: ''
    };
  }
};
</script>
