<template>
  <div>
    <v-toolbar border="b" class="ps-1" height="44" flat>
      <v-slide-group v-model="model" class="flex-grow-1" mandatory show-arrows>
        <v-slide-group-item v-if="tabs.includes('default')" value="default">
          <template #default="{ isSelected, toggle }">
            <v-btn
              :active="isSelected"
              class="ma-1 text-none"
              size="small"
              variant="text"
              @click="toggle"
            >
              Default
            </v-btn>
          </template>
        </v-slide-group-item>

        <v-slide-group-item v-for="(tab, i) in tabs" :key="i" :value="tab">
          <template #default="{ isSelected, toggle }">
            <v-btn
              :active="isSelected"
              class="ma-1 text-none"
              size="small"
              variant="text"
              @click="toggle"
            >
              {{ tab }}
            </v-btn>
          </template>
        </v-slide-group-item>
      </v-slide-group>

      <v-tooltip location="bottom">
        <template #activator="{ props: activatorProps }">
          <v-btn
            :icon="!show ? 'mdi-code-tags' : 'mdi-chevron-up'"
            class="me-1 text-medium-emphasis"
            density="comfortable"
            v-bind="activatorProps"
            @click="show = !show"
          />
        </template>

        <span>{{ show ? 'hide-source' : 'view-source' }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-layout :class="['border-b', !show && 'border-opacity-0']">
      <v-main>
        <v-sheet class="py-14 px-4 d-flex align-center" min-height="300" rounded="0">
          <div class="flex-fill">
            <slot />
          </div>
        </v-sheet>
      </v-main>

      <v-navigation-drawer
        v-if="$slots.configuration"
        v-model="tune"
        location="right"
        name="tune"
        width="250"
        permanent
        touchless
      >
        <v-list>
          <div class="px-4 usage-example pt-2">
            <v-defaults-provider
              :defaults="{
                global: {
                  density: 'compact',
                  hideDetails: true,
                  step: 1
                }
              }"
            >
              <slot name="configuration" />
            </v-defaults-provider>
          </div>
        </v-list>
      </v-navigation-drawer>
    </v-layout>
    <v-expand-transition>
      <div v-if="show">
        <div class="pa-2">
          <CodeMarkup :code="code" />
        </div>

        <div v-if="script" class="pa-2 pt-0">
          <CodeMarkup :code="script" language="js" />
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
// https://github.com/vuetifyjs/vuetify/blob/389be098a2ee71de21365bf5446be77cddfdfe5f/packages/docs/src/components/examples/UsageExample.vue
import CodeMarkup from './CodeMarkup';

export default {
  name: 'UsageExample',
  components: { CodeMarkup },
  props: {
    name: {
      type: String,
      default: 'usage-example'
    },
    code: { type: String, default: '' },
    tabs: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [Array, String],
      default: () => [],
      required: true
    },
    script: { type: String, default: '' }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      tune: true,
      show: true
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
};
</script>

<style lang="scss"></style>
