<template>
  <v-sheet ref="root" :rounded="rounded" theme="dark" class="app-markup overflow-hidden" dir="ltr">
    <v-toolbar v-if="resource" class="px-1" height="44">
      <v-sheet
        v-if="resource"
        class="text-body-2 px-3 pt-3 text-medium-emphasis"
        color="transparent"
        height="44"
        rounded="tl"
      >
        <v-icon icon="mdi-file-tree" />

        {{ resource }}
      </v-sheet>
    </v-toolbar>

    <v-tooltip location="start">
      <template #activator="{ props: activatorProps }">
        <v-fade-transition hide-on-leave>
          <v-btn
            :key="icon"
            :icon="icon"
            class="text-disabled me-3 mt-1 app-markup-btn"
            density="comfortable"
            style="position: absolute; right: 0; top: 0"
            v-bind="activatorProps"
            variant="text"
            @click="copy"
          />
        </v-fade-transition>
      </template>

      <span>{{ 'Copy Source' }}</span>
    </v-tooltip>

    <div class="pa-4 pe-12">
      <slot>
        <pre v-if="inline" :class="className">
            <code :class="className" v-html="highlighted" />
          </pre>

        <code v-else :class="className" v-html="highlighted" />
      </slot>
    </div>
  </v-sheet>
</template>

<script setup>
// Adapted from https://github.com/vuetifyjs/vuetify/blob/c7c2fa4dee1e510763341e97dd7976ad0ae047e4/packages/docs/src/components/app/Markup.vue

// Styles
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import { computed, ref, watchEffect } from 'vue';
import wait from './wait';
// Types

const props = defineProps({
  resource: String,
  code: null,
  inline: Boolean,
  language: {
    type: String,
    default: 'markup'
  },
  rounded: {
    type: Boolean,
    default: true
  }
});

// Transform inline links in typescript into actual links
Prism.languages.insertBefore('typescript', 'string', {
  hyperlink: /<a.*?>(.*?)<\/a>/g
});
Prism.hooks.add('wrap', (env) => {
  if (env.type === 'hyperlink' && env.tag !== 'a') {
    // eslint-disable-next-line no-param-reassign
    env.tag = 'a';
    // eslint-disable-next-line no-param-reassign
    env.content = env.content.replaceAll('&lt;', '<');
    // eslint-disable-next-line no-param-reassign
    env.attributes.href = /href="(.*?)"/.exec(env.content)?.[1] || '';
    // eslint-disable-next-line no-param-reassign
    env.attributes.target = '_blank';
    // env.content = stripLinks(env.content)[0];
  }
});

const clicked = ref(false);
const root = ref();

const highlighted = ref('');
watchEffect(async () => {
  highlighted.value =
    props.code &&
    props.language &&
    Prism.highlight(await props.code, Prism.languages[props.language], props.language);
});

const className = computed(() => `language-${props.language}`);
const icon = computed(() => (clicked.value ? 'mdi-check' : 'mdi-clipboard-text-outline'));

async function copy() {
  const el = root.value?.$el.querySelector('code');

  navigator.clipboard.writeText(props.code || el?.innerText || '');

  clicked.value = true;

  await wait(2000);

  clicked.value = false;
}
</script>

<style lang="scss">
.v-sheet.app-markup {
  position: relative;

  &:not(:hover) {
    .app-markup-btn {
      opacity: 0 !important;
    }

    .v-btn--copy .v-icon {
      opacity: 0.4;
    }
  }

  code,
  pre {
    background: none;
    color: currentColor !important;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1rem;
    font-weight: 300;
    hyphens: none;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    tab-size: 4;
    text-align: left;
    text-shadow: none;
    white-space: pre-wrap;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
  }

  pre,
  code {
    &::after {
      bottom: 0.5rem;
      color: hsla(0, 0%, 19%, 0.5);
      font-family: inherit;
      font-size: 0.7rem;
      font-weight: 700;
      pointer-events: none;
      position: absolute;
      right: 1rem;
      text-transform: uppercase;
    }
  }

  pre.language-bash::after {
    content: ' sh ';
  }

  pre.language-html::after {
    content: 'html';
  }

  pre.language-js::after {
    content: ' js ';
  }

  pre.language-json::after {
    content: 'json';
  }

  pre.language-sass::after {
    content: 'sass';
  }

  code.language-scss::after {
    content: 'scss';
  }

  pre.language-ts::after {
    content: ' ts ';
  }

  pre.language-vue::after {
    content: 'vue';
  }

  // TODO: handle this differently
  &.v-theme--blackguard,
  &.v-theme--dark {
    --prism-interpolation: var(--prism-operator);

    code,
    pre {
      color: #ccc !important;

      &::selection,
      ::selection {
        background-color: #113663;
      }

      &::after {
        color: hsla(0, 0%, 50%, 1);
      }
    }

    &.v-sheet--outlined {
      border: thin solid hsla(0, 0%, 100%, 0.12) !important;
    }

    .token.operator,
    .token.string {
      background: none;
    }

    .token.comment,
    .token.block-comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: #999;
    }

    .token.punctuation {
      color: #ccc;
    }

    .token.tag,
    .token.attr-name,
    .token.namespace,
    .token.deleted {
      color: #e2777a;
    }

    .token.function-name {
      color: #6196cc;
    }

    .token.boolean,
    .token.number,
    .token.function {
      color: #f08d49;
    }

    .token.property,
    .token.class-name,
    .token.constant,
    .token.symbol {
      color: #f8c555;
    }

    .token.selector,
    .token.important,
    .token.atrule,
    .token.keyword,
    .token.builtin {
      color: #cc99cd;
    }

    .token.string,
    .token.char,
    .token.attr-value,
    .token.regex,
    .token.variable {
      color: #7ec699;
    }

    .token.operator,
    .token.entity,
    .token.url {
      color: #67cdcc;
    }

    .token.important,
    .token.bold {
      font-weight: bold;
    }

    .token.italic {
      font-style: italic;
    }

    .token.entity {
      cursor: help;
    }

    .token.inserted {
      color: green;
    }
  }
}
</style>
