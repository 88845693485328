<template>
  <cs-view single-view>
    <template #content>
      <v-expansion-panels v-model="helixActivePanels" variant="accordion" multiple>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Buttons</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixButtons />
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Table</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HxTable
              :title="title"
              :items="items"
              :headers="headers"
              :loading="loading"
              :show-toolbar="showToolbar"
              :show-select="showSelect"
              :items-per-page="itemsPerPage"
              :items-per-page-options="itemsPerPageOptions"
            />
            <v-container>
              <v-row>
                <v-switch
                  v-model="loading"
                  :label="`Loading: ${loading.toString()}`"
                  color="primary"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showSelect"
                  :label="`Show Select Button: ${showSelect.toString()}`"
                  color="primary"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showTitle"
                  :label="`Show title: ${showTitle.toString()}`"
                  color="primary"
                  @change="toggleTitle"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showToolbar"
                  :label="`Show toolbar: ${showToolbar.toString()}`"
                  color="primary"
                ></v-switch>
              </v-row>
            </v-container>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Search</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HxSearch />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Content Card</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-container class="d-flex flex-wrap ga-3">
              <v-card elevation="4">
                <v-card-title class="font-weight-bold">White</v-card-title>
                <v-card-subtitle> White: [variant: flat | color: white] </v-card-subtitle>
                <v-card-text class="d-flex ga-3 flex-wrap">
                  <HxContentCard>
                    <template #header>
                      <div class="text-black ma-2">{{ cardTitle }}</div>
                    </template>
                    <template #body>
                      <div class="text-black">
                        {{ cardContent }}
                      </div>
                    </template>
                  </HxContentCard>
                </v-card-text>
              </v-card>
              <v-card elevation="4">
                <v-card-title class="font-weight-bold">Grey</v-card-title>
                <v-card-subtitle> Grey: [variant: flat | color: grey] </v-card-subtitle>
                <v-card-text class="d-flex ga-3 flex-wrap">
                  <HxContentCard color="grey">
                    <template #header>
                      <div class="text-black ma-2">{{ cardTitle }}</div>
                    </template>
                    <template #body>
                      <div class="text-black">
                        {{ cardContent }}
                      </div>
                    </template>
                  </HxContentCard>
                </v-card-text>
              </v-card>
              <v-card elevation="4">
                <v-card-title class="font-weight-bold">MD Elevation</v-card-title>
                <v-card-subtitle> MD: [variant: MD | color: white] </v-card-subtitle>
                <v-card-text class="d-flex ga-3 flex-wrap">
                  <HxContentCard color="white" elevation="md">
                    <template #header>
                      <div class="text-black ma-2">{{ cardTitle }}</div>
                    </template>
                    <template #body>
                      <div class="text-black">
                        {{ cardContent }}
                      </div>
                    </template>
                  </HxContentCard>
                </v-card-text>
              </v-card>
              <v-card elevation="4">
                <v-card-title class="font-weight-bold">XL Elevation</v-card-title>
                <v-card-subtitle> XL: [variant: XL | color: white] </v-card-subtitle>
                <v-card-text class="d-flex ga-3 flex-wrap">
                  <HxContentCard color="white" elevation="xl">
                    <template #header>
                      <div class="text-black ma-2">{{ cardTitle }}</div>
                    </template>
                    <template #body>
                      <div class="text-black">
                        {{ cardContent }}
                      </div>
                    </template>
                  </HxContentCard>
                </v-card-text>
              </v-card>
              <HxContentCard :height="height" :color="cardColor" :elevation="cardElevation">
                <template v-if="showCardTitle" #header>
                  <div class="text-black">{{ cardTitle }}</div>
                </template>
                <template v-if="showCardContent" #body>
                  <div class="text-black">
                    {{ cardContent }}
                  </div>
                </template>
                <template v-if="showAction" #footer>
                  <v-container>
                    <v-row>
                      <HxSearch search="" />
                      <v-spacer></v-spacer>
                      <HxButton
                        id="buttonId"
                        size="large"
                        :label="buttonLabel"
                        variant="primary"
                        right-icon="mdi-plus-circle-outline"
                      />
                    </v-row>
                  </v-container>
                </template>
              </HxContentCard>

              <v-row>
                <v-col>
                  <v-card title="Control" elevation="4">
                    <v-card-text class="d-flex justify-space-between">
                      <v-switch
                        v-model="showGrey"
                        :label="`Show Grey: ${cardColor.toString()}`"
                        @change="toggleGrey"
                      ></v-switch>
                      <v-switch
                        v-model="showAction"
                        :label="`Show Action: ${showAction.toString()}`"
                      ></v-switch>
                      <v-switch
                        v-model="showCardTitle"
                        :label="`Show Title: ${showCardTitle.toString()}`"
                      ></v-switch>
                      <v-switch
                        v-model="showCardContent"
                        :label="`Show Content: ${showCardContent.toString()}`"
                      ></v-switch>
                      <v-switch
                        v-model="showScroll"
                        :label="`Show Scroll: ${showScroll.toString()}`"
                        @change="toggleScroll"
                      ></v-switch>
                    </v-card-text>
                    <v-card-text>
                      <v-select
                        v-model="cardElevation"
                        label="Elevation"
                        :items="['flat', 'md', 'xl']"
                        max-width="200"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Checkboxes</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixCheckboxes />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Toggle Switch</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixToggleSwitches />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Radio Button</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixRadioButtons />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Chips</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixChips />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Tooltip</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixTooltip />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Modal</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixModal />
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Options</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixOptions />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title class="title-1">Helix Menu</v-expansion-panel-title>
          <v-expansion-panel-text>
            <HelixMenus />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </cs-view>
</template>

<script>
import HelixButtons from '@/components/helix/HelixButtons';
import HelixCheckboxes from '@/components/helix/HelixCheckboxes';
import HelixToggleSwitches from '@/components/helix/HelixToggleSwitch';
import HelixRadioButtons from '@/components/helix/HelixRadioButtons';
import HelixTooltip from '@/components/helix/HelixTooltip';
import HelixChips from '@/components/helix/HelixChips';
import HelixModal from '@/components/helix/HelixModal';
import HelixMenus from '@/components/helix/HelixMenus';
import { mapMutations, mapState } from 'vuex';
import HelixOptions from '@/components/helix/HelixOptions';
import * as types from '../store/mutationTypes';

const dummyData = [...Array(100)].map((title, index) => {
  return {
    id: index,
    portal: 'aacg-complispacelearn.mygo1.com',
    title: 'About Me - New Standards - Introduction',
    type: 'Video',
    learningObjectID: '92381234',
    assigned: 3,
    pending: 0,
    notStarted: 0
  };
});
export default {
  name: 'HelixComponents',
  components: {
    HelixButtons,
    HelixCheckboxes,
    HelixToggleSwitches,
    HelixRadioButtons,
    HelixTooltip,
    HelixChips,
    HelixModal,
    HelixOptions,
    HelixMenus
  },
  data() {
    return {
      title: undefined,
      cardTitle: 'Helix Card Title',
      cardContent:
        'The Content Engine (CE) is Jae Testing 3rd of December a decoupled Content Management System (CMS) developed for the primary purpose of delivering writen contextualised content at scale. The CE core technology manages content analogous to how Github managed code. Authors write their content in MS Word documents abiding by the protocols of the CE Authoring Methodology (CEAM). The content is imported into the CE where they are converted into MarkDown files.',
      cardElevation: undefined,
      cardVariant: 'outlined',
      showElevation: false,
      showTitle: false,
      showGrey: false,
      showCardTitle: true,
      showCardContent: true,
      cardColor: 'white',
      showAction: true,
      height: undefined,
      showScroll: false,
      items: dummyData,
      showSelect: false,
      itemsPerPage: 10,
      buttonLabel: 'Button',
      itemsPerPageOptions: [10, 20, 30],
      headers: [
        {
          title: 'Portal',
          value: 'portal',
          key: 'portal',
          minWidth: 275
        },
        { title: 'Title', value: 'title', key: 'title', minWidth: 350 },
        { title: 'Type', value: 'type', key: 'type' },
        {
          title: 'Learning Object ID',
          value: 'learningObjectID',
          key: 'learningObjectID',
          minWidth: 175
        },
        { title: 'Assigned', value: 'assigned', key: 'assigned' },
        { title: 'Pending', value: 'pending', key: 'pending' },
        { title: 'Not Started', value: 'notStarted', key: 'notStarted' }
      ],
      loading: false,
      showToolbar: false
    };
  },
  computed: {
    ...mapState({
      helixComponentsStore: 'helix-components'
    }),
    helixActivePanels: {
      get() {
        return this.helixComponentsStore.helixActivePanels;
      },
      set(value) {
        this.setHelixActivePanels(value);
      }
    }
  },
  methods: {
    ...mapMutations({
      setHelixActivePanels: types.HELIX_COMPONENTS_SET_HELIX_ACTIVE_PANELS
    }),
    toggleTitle() {
      if (this.showTitle) {
        this.title = 'Helix Table Title';
      } else {
        this.title = undefined;
      }
    },
    toggleGrey() {
      if (this.showGrey) {
        this.cardColor = 'grey';
      } else {
        this.cardColor = 'white';
      }
    },
    toggleScroll() {
      if (this.showScroll) {
        this.height = 200;
      } else {
        this.height = undefined;
      }
    }
  }
};
</script>
